import { withYup } from '@remix-validated-form/with-yup';
import { V2ActionHandler, actionV2Response, handleActionV2 } from '@storefront/util/handleAction.server';
import { FormValidationError } from '@storefront/util/validation/validation-error';
import { ActionFunctionArgs } from '@vercel/remix';
import * as Yup from 'yup';
import { getUserSession, setUserSessionCookie } from '~/session/user-session';
import { createMedusaClient } from '../../libs/util/medusa/client.server';

export const postalCodeFieldYupValidation = Yup.string()
  .required('Postal Code is required.')
  .test('len', 'Please enter a valid postal code', (val) => {
    const length = val?.replace(/\s/g, '')?.length;
    if (!length) return false;
    return length >= 3;
  });

export const postalCodeValidator = withYup(
  Yup.object().shape({
    postalCode: postalCodeFieldYupValidation,
    productId: Yup.string().optional(),
  }),
);

export enum PostalCodeActions {
  verifyPostalCode = 'verifyPostalCode',
}

export type VerifyPostalCodePayload = {
  postalCode: string;
  productId?: string;
};

export interface VerifyPostalCodeResponse {
  valid: boolean;
  postalCode: string;
}

const verifyPostalCode: V2ActionHandler<VerifyPostalCodeResponse | Response> = async (
  payload: VerifyPostalCodePayload,
  { request, response },
) => {
  const session = await getUserSession(request.headers.get('Cookie'));

  const client = await createMedusaClient({ request });

  const result = await postalCodeValidator.validate(payload);

  if (result.error) throw new FormValidationError(result.error);

  const { postalCode } = payload;

  const validationResponse = await client.postalCode.validatePostalCode({ postalCode });

  const isSupported = validationResponse.isValid;

  session.set('user-postal-code', {
    city: 'Toronto',
    postalCode,
    isSupportedPostalCode: isSupported,
  });

  await setUserSessionCookie(response!.headers, session);

  return actionV2Response<VerifyPostalCodeResponse | Response>({ valid: isSupported, postalCode }, request);
};

const actions = {
  verifyPostalCode,
};

export async function action(actionArgs: ActionFunctionArgs) {
  return await handleActionV2({ actionArgs, actions });
}
